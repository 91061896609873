<template lang="pug">
v-card
  TableV2(
    :actions="actions"
    :currentPage="items.current"
    :headers="headers"
    :isLoading="isLoading"
    :items="items.results"
    :pageCount="items.count"
    :querySearch="getListDigitalizationDocumentSC"
  ).mx-8
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data () {
    return {
      headers: [
        { value: 'created_at', text: this.$t('tableHeaders.created_at') },
        { value: 'sailor.id', text: this.$t('tableHeaders.sailor_id'), sortable: false },
        { value: 'sailor.full_name_ukr', text: this.$t('tableHeaders.sailor_full_name'), sortable: false },
        { value: 'sailor.full_name_eng', text: this.$t('tableHeaders.sailor_full_name_eng'), sortable: false },
        { value: 'verificationDocumentTypes', text: this.$t('tableHeaders.type_document'), sortable: false },
        { value: 'status_document', text: this.$t('tableHeaders.status_document') },
        { value: 'event', class: 'd-flex justify-center align-center', cellClass: 'text-center', text: this.$t('actions'), sortable: false }
      ],
      actions: [
        {
          id: 1,
          to: (item) => ({ path: `/${this.$route.name}/${item.id}`, query: { ...this.$route.query } }),
          color: 'blue',
          name: 'mdi-arrow-right',
          tooltip: 'tooltip.go',
          buttonOptions: { xSmall: false, icon: true }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      items: (state) => state.documents.digitalDocument,
      isLoading: (state) => state.documents.isLoading
    })
  },
  methods: {
    ...mapActions(['getListDigitalizationDocumentSC'])
  }
}
</script>
